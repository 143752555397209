import { postToSaleServer } from './common';
export async function addTaoBaoAuth(appKey,authInfo){
    return await postToSaleServer('/taobao/add_auth',{
        appKey:appKey,authInfo:authInfo
    });
}
/**
 * 获得淘宝商品类目
 */
export async function getCats(pid=0){
    return await postToSaleServer('/taobao/item/cats',{pid:pid});
}
/**
 * 添加商品
 * @param {*} shopId 
 * @param {*} item 
 */
export async function addItem(shopId,itemId,item){
    return await postToSaleServer('/taobao/item/add',{
        shopId:shopId,
        itemId:itemId,
        item:item
    });
}
/**
 * 获得订单列表
 * @param {*} shopId 
 */
export async function getOrders(shopId){
    return await postToSaleServer('/taobao/order/list',{
        shopId:shopId,
        start:'2019-11-21 00:00:00',
        end:'2019-11-21 11:59:59'
    });
}
export async function getSchema(catId){
    return await postToSaleServer('/taobao/item/schema/'+catId);
}